import {
    DKLabel,
    DKInput,
    INPUT_TYPE,
    DKButton,
    showAlert
} from "deskera-ui-library";
import { useEffect, useState } from "react";

import CountryUtility from "../../Utility/CountryUtility";
import '../../css/Common.css'
import RouteManager, { PAGE_ROUTES } from "../../Managers/RouteManager";

import AccountDetailsForm from "../AccountDetails/AccountDetailsForm";
import PersonaliseAccountForm from "../AccountDetails/PersonaliseAccountForm";

import SignUp from "../../Services/SignUp";

import ReactRecaptcha3 from 'react-google-recaptcha3';
import ApiManager from "../../Managers/ApiManager";
import MixpanelService, { MIXPANEL_EVENT_NAMES } from "../../Managers/MixPanelManager";
import { isValidEmail } from "../../Utility/Utility";

export default function SignUpForm(props: any) {

    const [formValues, setFormValues] = useState({
        email: "",
        phoneNo: "",
        countryDialCode: "",
        name: "",
        companyName: "",
        password: ""
    });
    const [isCreateAccTapped, setIsCreateAccTapped] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isNormalUser, setIsNormalUser] = useState(true);
    const [isBookkeeper, setIsBookkeeper] = useState(false);
    const [isUserPartner, setIsUserPartner] = useState(false);
    const [isAccCreated, setIsAccCreated] = useState(false);
    const [isAccFilled, setIsAccFilled] = useState(false);

    useEffect(() => {
        RouteManager.setPresenter({props});

        const pathname = window.location.pathname;

        if(pathname === PAGE_ROUTES.USER_PARTNER_SIGN_UP){
            setIsUserPartner(true);
            setIsBookkeeper(true);
        } else {
            setIsNormalUser(true);
        }

        ReactRecaptcha3.init(process.env.REACT_APP_reCAPTCHA_SITE_KEY).then(
            (status) => {
              console.log(status);
            }
        );
    },[])

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    const getHeader = () => {
        let title;
        
        if (isBookkeeper) {
            if (isUserPartner) {
                title = "Sign up for User Partners"
            } else {
                title = "Sign up for Deskera Book-keeper"
            }
        } else {
            title = "Sign up with your work email"
        }
        return (
            <div className="column display-box">
                <DKLabel text={title} className="fw-m fs-xxl mt-xl mb-m" />
                <DKLabel text="User Credentials" className="fw-b fs-m mt-xl mb-m" />
            </div>
        )
    }

    const signUpEmail = () => <div className="column display-box">
        <DKInput
            name="email"
            placeholder="Email Address *"
            value={formValues.email}
            type={INPUT_TYPE.EMAIL}
            onChange={(value: any) => {
                setFormValues({...formValues, email: value})
            }}
            canValidate={isCreateAccTapped}
            required={true}
            errorMessage="Please enter a valid email"
            className="mt-m mb-m"
        />
        <div style={{width:"100%", display:"flex"}}>
            <div style={{width:"170px"}} className="mr-m">
                <DKInput
                    placeholder=""
                    required={false}
                    value={formValues.countryDialCode}
                    formatter={(obj: any) => {
                        return obj;
                    }}
                    type={INPUT_TYPE.DROPDOWN}
                    onChange={(value: any) => {
                        setFormValues({
                            ...formValues, countryDialCode: value.dial_code
                        })
                    }}
                    className="mt-m"
                    dropdownConfig={{
                        className: '',
                        style: {width:"100px"},
                        allowSearch: true,
                        searchableKey: 'name',
                        data: CountryUtility.getCountryData(),
                        renderer: (index: any, obj: any) => {
                            return (
                                <div className="flex flex-row w-full justify-content-between" style={{width:"80px"}}>
                                    <DKLabel
                                        style={{ fontSize: '13px' }}
                                        className="text-base border-radius-s"
                                        text={obj.name +" ("+obj.dial_code+")"}
                                    />
                                </div>
                            );
                        },
                        onSelect: (index: any, value: any) => {}
                    }}
                />
            </div>
            <DKInput
                name="phoneNumber"
                placeholder="Phone Number (optional)"
                value={formValues.phoneNo}
                type={INPUT_TYPE.INPUT}
                onChange={(value: any) => {
                    setFormValues({...formValues, phoneNo: value})
                }}
                canValidate={isCreateAccTapped}
                required={false}
                errorMessage="Please enter a valid phone number"
                className="mt-m"
            />
        </div>
        <DKButton
            title={loading ? "Please wait" : "Create Account"}
            className="fs-m fw-m mr-r mt-m pt-m pb-m text-white"
            style={{backgroundColor:"black", width: "100%", justifyContent: "center"}}
            onClick={() => {
                setLoading(true);
                checkIfUserExist();
            }}
            disabled={loading ? true: false}
        />
        {!isUserPartner &&
            <div style={{display: "flex", width: "100%", justifyContent: "center", flexWrap:"wrap"}} className="mt-s">
                <DKLabel text={isBookkeeper ? "Not a book-keeper? " : "Are you a book-keeper? "} className="fs-m pt-m signup-bookkeeper-text"/>
                <DKButton
                    title={isBookkeeper? 'Sign up for regular account' : 'Sign up for Deskera Book keeper'}
                    className="fw-h fs-m text-red"
                    style={{paddingLeft:"0px"}}
                    onClick={() => {
                        MixpanelService.pageTrack("signup-bookkeeper");
                        setIsBookkeeper(!isBookkeeper);
                    }}
                />
            </div>
        }
        <div style={{display: "flex", width: "100%", justifyContent: "center", flexWrap:"wrap"}} className={isUserPartner? "mt-m mb-m" : "mb-m"}>
            <DKLabel text="Already have an account?  " className="fs-m pt-m signup-bookkeeper-text"/>
            <DKButton
                title={'Sign in'}
                className="fw-h fs-m text-red"
                style={{paddingLeft:"0px", paddingBottom: "0px", paddingTop: "0px"}}
                onClick={() => {
                    MixpanelService.pageTrack("signin");
                    RouteManager.navigateToPage(PAGE_ROUTES.SIGN_IN, null);
                }}
            />
        </div>
    </div>

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    const firstStep = () => <div>
        {getHeader()}
        {signUpEmail()}
    </div>

    function checkIfUserExist() {
        setIsCreateAccTapped(true)
        if(isValidEmail(formValues.email)) {
            SignUp.checkIfUserExist(formValues.email, formValues.phoneNo)
            .then((res:any) => {
                if(res.contactExists || res.emailExists){
                    showAlert("Email has been used", "Please try with another email.");
                    setLoading(false);
                } else {
                    setIsAccCreated(true);
                }
            })
            .catch((err) => {
                showAlert("Error occured!", err)
            })
        } else{
            setLoading(false);
        }
    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    function emailSignUp(val) {
        let contact = formValues.countryDialCode + formValues.phoneNo;

        let data;

        data = {
            contact: formValues.phoneNo.length > 0 ? contact : null,
            emailId: formValues.email,
            firstName: formValues.name,
            isBookkeeper: isBookkeeper,
            isTeam: true,
            lastName:"",
            organizationName: formValues.companyName,
            password: formValues.password,
            shouldSendEmail: true
        }

        if(isUserPartner) {
            data.isPartner = true
        }

        ReactRecaptcha3.getToken().then(
            (token) =>{
                ApiManager.signUp(
                    data,
                    token,
                    (data) => {
                        let mixpanelrequest = Object.assign({}, data);
                        mixpanelrequest.password = "";
                        MixpanelService.eventTrack(MIXPANEL_EVENT_NAMES.SIGNUP_SUCCESS, mixpanelrequest)
                        let params = "mode=POSTSIGNUP&email="+encodeURIComponent(formValues.email)+"&name="+ formValues.name +"&verified=true";
                        RouteManager.navigateToPage(PAGE_ROUTES.EMAIL_VERIFICATION, params);
                    },
                    (error) => {
                        console.log("form err", error)
                    }
                )
               
            }
        )        
    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    return (
        <div className="cards-main-holder column align-items-center" style={{display: "flex", justifyContent:"center", height: "100%"}}>
            <div className="card-background card-div border-radius-s shadow-m display-box align-items-center position-relative">
                {!isAccCreated && firstStep()}
                {isAccCreated && !isAccFilled && 
                    <AccountDetailsForm
                        nextTapped={(data) => {
                            setIsAccFilled(true);
                            setFormValues({
                                ...formValues, 
                                name: data.name,
                                companyName: data.compName,
                                password: data.password
                            })
                        }}
                    />
                }
                {isAccCreated && isAccFilled &&
                    <PersonaliseAccountForm
                        submitForm={(val)=> emailSignUp(val)}
                    />
                }
            </div>
        </div>
    )
}